$icomoon-dai-font-family: "desktop_app" !default;
$icomoon-dai-font-path: "fonts" !default;

$dai-account: "\e900";
$dai-activities: "\e901";
$dai-backarrow: "\e902";
$dai-backspace: "\e903";
$dai-buttons: "\e904";
$dai-buttons2: "\e905";
$dai-call: "\e906";
$dai-contacts: "\e907";
$dai-CRM: "\e908";
$dai-crmsettings: "\e909";
$dai-device: "\e90a";
$dai-endcall: "\e90b";
$dai-group: "\e90c";
$dai-leanguage: "\e90d";
$dai-logout: "\e90e";
$dai-mute: "\e90f";
$dai-notifications: "\e910";
$dai-pause: "\e911";
$dai-person: "\e912";
$dai-plus: "\e913";
$dai-popup: "\e914";
$dai-reset: "\e915";
$dai-settings: "\e916";
$dai-transfer: "\e918";
$dai-update: "\e919";
$dai-video: "\e91a";

