/* You can add global styles to this file, and also import other style files */

// // ng-voxloud
@import '../node_modules/voxloud-ui-toolkit/dist/scss/components/variables.scss';
@import "../node_modules/voxloud-ui-toolkit/dist/css/style.min.css";

// desktop-app specific font icons
@import "./assets/fonticons/style.scss";

* {
  font-family: $vox-gtEestiPro-font;
}

*, *:hover, *:focus {
  outline: none !important;
}

body {
  overflow-y: hidden;
}

ngb-modal-backdrop {
  // for some reason ngb bootstrap is putting 1055 which make the dark backdrop
  // on top of modal content
  // 1040 is the value bootstrap uses
  z-index: 1040 !important;
}

.ladda-button {
  border: none !important;
}

// Common style for dropdown menu
.dropdown-style {
  &-menu {
    box-shadow: 0px 8px 13px 5px $light-grey;
    border-color: $white;
  }
  &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      &:active {
        background-color: $lighter-grey;
        color: $black;
      }
      & i {
          font-size: 20px;
          margin-right: 8px;
      }
  }
}

.on-top {
  &.modal-backdrop {
    z-index: 1998 !important;
  }
  &.modal-dialog,
  &.modal{
    z-index: 1999;
  }
}

vox-phone-text-field {
  .phone-tf-container {
    .input-container {
      .flag-container {
        .flag-selected {
          .flag::after {
            font-size: 1.1rem;
            top: 2.3px;
            right: -1rem;
          }
        }
      }
    }
  }
}

.unselectable {
  user-select: none !important;
}