@import "variables";

@font-face {
  font-family: '#{$icomoon-dai-font-family}';
  src:  url('#{$icomoon-dai-font-path}/#{$icomoon-dai-font-family}.eot?f7tunb');
  src:  url('#{$icomoon-dai-font-path}/#{$icomoon-dai-font-family}.eot?f7tunb#iefix') format('embedded-opentype'),
    url('#{$icomoon-dai-font-path}/#{$icomoon-dai-font-family}.ttf?f7tunb') format('truetype'),
    url('#{$icomoon-dai-font-path}/#{$icomoon-dai-font-family}.woff?f7tunb') format('woff'),
    url('#{$icomoon-dai-font-path}/#{$icomoon-dai-font-family}.svg?f7tunb##{$icomoon-dai-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dai-"], [class*=" dai-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-dai-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dai-account {
  &:before {
    content: $dai-account; 
  }
}
.dai-activities {
  &:before {
    content: $dai-activities; 
  }
}
.dai-backarrow {
  &:before {
    content: $dai-backarrow; 
  }
}
.dai-backspace {
  &:before {
    content: $dai-backspace; 
  }
}
.dai-buttons {
  &:before {
    content: $dai-buttons; 
  }
}
.dai-buttons2 {
  &:before {
    content: $dai-buttons2; 
  }
}
.dai-call {
  &:before {
    content: $dai-call; 
  }
}
.dai-contacts {
  &:before {
    content: $dai-contacts; 
  }
}
.dai-CRM {
  &:before {
    content: $dai-CRM; 
  }
}
.dai-crmsettings {
  &:before {
    content: $dai-crmsettings; 
  }
}
.dai-device {
  &:before {
    content: $dai-device; 
  }
}
.dai-endcall {
  &:before {
    content: $dai-endcall; 
  }
}
.dai-group {
  &:before {
    content: $dai-group; 
  }
}
.dai-leanguage {
  &:before {
    content: $dai-leanguage; 
  }
}
.dai-logout {
  &:before {
    content: $dai-logout; 
  }
}
.dai-mute {
  &:before {
    content: $dai-mute; 
  }
}
.dai-notifications {
  &:before {
    content: $dai-notifications; 
  }
}
.dai-pause {
  &:before {
    content: $dai-pause; 
  }
}
.dai-person {
  &:before {
    content: $dai-person; 
  }
}
.dai-plus {
  &:before {
    content: $dai-plus; 
  }
}
.dai-popup {
  &:before {
    content: $dai-popup; 
  }
}
.dai-reset {
  &:before {
    content: $dai-reset; 
  }
}
.dai-settings {
  &:before {
    content: $dai-settings; 
  }
}
.dai-transfer {
  &:before {
    content: $dai-transfer; 
  }
}
.dai-update {
  &:before {
    content: $dai-update; 
  }
}
.dai-video {
  &:before {
    content: $dai-video; 
  }
}

